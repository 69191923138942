import { AlertTriangle } from 'react-feather';
import { Box, Heading } from '@chakra-ui/react';
import { Stack } from '~/design-system/stack';
import { Text } from '~/design-system/text';
import { useMemo } from 'react';
import { useRouteError } from '@remix-run/react';

export function GenericErrorBoundary() {
  const error = useRouteError();

  const formattedError = useMemo(() => {
    if (error instanceof Error) {
      return (
        <Stack gap='2' alignItems='center'>
          <Heading size='md'>{error.message}</Heading>
          <Box>{error.stack}</Box>
        </Stack>
      );
    }
  }, [error]);

  return (
    <Stack
      height='100vh'
      alignItems='center'
      justifyContent='center'
      gap='8'
      overflow='auto'
      wrap='wrap'
      padding='8'
    >
      <AlertTriangle size={164} color='#ff9200' strokeWidth={1} />
      <Heading size='lg'>Something went wrong.</Heading>
      <Text textAlign='center'>
        If the issue persists, please contact Navace with the below error
        details and we'll fix it up. <br /> Apologies for the inconvenience.
      </Text>
      <Stack
        bg='gray.200'
        color='gray.600'
        paddingY='4'
        paddingX='8'
        borderRadius='md'
        gap='2'
        width='60%'
      >
        <Heading size='md' textAlign='center'>
          Raw Error
        </Heading>
        <Text maxHeight='400px' overflow='auto'>
          {formattedError}
        </Text>
      </Stack>
    </Stack>
  );
}
