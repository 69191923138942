import { ChakraProvider, ColorModeScript, extendTheme } from '@chakra-ui/react';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  json,
  useLoaderData,
  useRouteError,
} from '@remix-run/react';

import { ChakraThemeProvider } from '~/design-system/useChakraTheme';
import { GenericErrorBoundary } from './ui-components/GenericErrorBoundary';
import { GlobalErrorDialogProvider } from './ui-components/GlobalErrorDialog/GlobalErrorDialogProvider';
import { captureRemixErrorBoundaryError } from '@sentry/remix';

import { ClientOnly } from './ui-components/ClientOnly';

export async function loader() {
  return json({
    BENV: {
      STAGE: process.env.STAGE || 'local',
    },
  });
}

function Document({
  children,
  title = 'Navace',
}: {
  children: React.ReactNode;
  title?: string;
}) {
  const data = useLoaderData<typeof loader>();
  return (
    <html lang='en'>
      <head>
        <link rel='preconnect' href='https://www.google.com' />
        <link
          rel='preconnect'
          href='https://www.gstatic.com'
          crossOrigin='anonymous'
        />
        <Meta />
        <meta charSet='UTF-8' />
        <meta name='viewport' content='width=device-width,initial-scale=1' />
        <title>{title}</title>
        <Links />
      </head>
      <body>
        <script
          suppressHydrationWarning
          dangerouslySetInnerHTML={{
            __html: `window.BENV = ${JSON.stringify(data?.BENV)}`,
          }}
        />
        <ClientOnly>
          <ColorModeScript />
        </ClientOnly>
        {children}

        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default function App() {
  const customTheme = extendTheme({
    styles: {
      global: {
        body: {
          height: '100vh',
          background: '#f3f3f3',
          color: '#2d3748',
          fontWeight: '500',
        },
        'ul, ol': {
          listStyle: 'none',
        },
      },
    },
    components: {
      Drawer: {
        sizes: {
          xl: {
            dialog: {
              maxW: '80vw',
              maxH: 'calc(100vh - 20px)',
              top: '10px !important',
              bottom: '10px !important',
              right: '10px !important',
              borderRadius: '10px',
            },
          },
        },
      },
      Modal: {
        sizes: {
          '8xl': {
            dialog: {
              maxW: 'calc(100vw - 20px)',
              maxH: 'calc(100vh - 20px)',
            },
          },
        },
      },
    },
    textStyles: {
      default: {
        lineHeight: '1.1rem',
      },
    },
  });

  return (
    <Document>
      <ChakraProvider theme={customTheme}>
        <ChakraThemeProvider>
          <GlobalErrorDialogProvider>
            <Outlet />
          </GlobalErrorDialogProvider>
        </ChakraThemeProvider>
      </ChakraProvider>
    </Document>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  return (
    <Document title='Navace'>
      <ChakraProvider>
        <GenericErrorBoundary />
      </ChakraProvider>
    </Document>
  );
}
